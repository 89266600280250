@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap);
html body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --main-green: #39B54A;
  --main-grey: #EBEBEB;
  --title-grey: #efefef;
  --light-blue: #13659f;
  --grey: #777;
  --dark-grey: #212120;
}
.carousel-caption {
  width: 25%;
}
html, body {
  font-family: 'Inter', sans-serif;
  line-height: 1.2em;
}
body .MuiTypography-root, body .MuiTableCell-root {
  font-family: 'Inter', sans-serif;
}
.MuiTypography-body1 {
  line-height: 1.2em;
}
h1, h2, h3, h4, h5, p, span, input, textarea, ul, li, table {
  font-family: 'Inter', sans-serif;
}
.header button {
  font-weight: 600;
  font-family: "Inter";
  color: #EBEBEB;
  color: var(--main-grey);
}
.ml-2, .MuiButtonBase-root.ml-2 {
  margin-left: 10px;
}
.mb-auto, .my-auto {
  margin-bottom: auto!important;
}
.mt-auto, .my-auto {
  margin-top: auto!important;
}
.carousel-caption {
  text-align: left;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  bottom: auto;
  bottom: initial;
}
.dfs-title, .MuiTypography-body1.dfs-title {
  font-size: 2rem;
}
.bg-grey {
  background-color: #EBEBEB;
  background-color: var(--main-grey);
}
.menu-subitem {
  color: #000;
}
.menu-subitem li:hover a {
  color: #fff
}
.MuiButton-text.btn-deal, .MuiButton-text.btn-deal:hover {
  background-color: #39B54A;
  background-color: var(--main-green);
  color: #FFF;
}
.MuiButton-text.btn-deal {
  font-weight: 600;
  font-family: "Inter";
  font-size: 13px;
}
.MuiButton-text.btn-deal.btn-deal-blue, .MuiButton-text.btn-deal.btn-deal-blue:hover {
  background-color: #13659f;
  background-color: var(--light-blue);
}
.btn-deal-light span {
  color: #212120;
  color: var(--dark-grey);
}
.return-btn-row.row {
  text-align: center;
  margin-top: 40px;
}
.title-2 {
  color: #FFF;
  font-size: 1.5rem
}
.text-right {
  text-align: right;
}
.h-100p {
  height: 100px;
}
header img {
  mix-blend-mode: multiply;
}
.header .btn-header-alto, .header a.inline button {
  color: #000;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  border-radius: 0px;
}
.btn-header-alto:hover, .btn-header-alto:active, .css-1e6y48t-MuiButtonBase-root-MuiButton-root.btn-header-alto:hover, .css-1e6y48t-MuiButtonBase-root-MuiButton-root.btn-header-alto:active, li.MuiMenuItem-root:hover {
  background-color: #13659f !important;
  background-color: var(--light-blue) !important;
  border-color: #13659f !important;
  border-color: var(--light-blue) !important;
  box-shadow: none;
  color: #fff !important
}
.MuiList-root li, ul.MuiList-root {
  background-color: #f5f5f5
}
.title {
  margin-bottom: 10px;
}
.title .title__text {
  padding: 40px;
}
.card-title-1, p.blue-text, .MuiTypography-body1.card-title-1, .blue-text th {
  color: #13659f;
  color: var(--light-blue);
  font-weight: bold;
}
.blue .MuiTypography-body1.card-title-1, .MuiPaper-root.blue, .blue .MuiButton-root {
  background-color: #13659f;
  background-color: var(--light-blue);
  color: #fff;
}
div.css-e53awj-MuiStack-root, .header a.inline div {
  display: inline;
}
.deal-footer {
  background-color: #333333;
  color: white;
}
.white {
  color: white;
}
.MuiToolbar-root a, a {
  text-decoration: none;
}
div.transparent {
  background-color: transparent;
}
.grey {
  color: #777;
  color: var(--grey)
}
.h3, h3 {
  font-size: 25px;
}
.steps-section {
  padding: 100px 0;
  background-color: #f7f8f8;
}
.steps-section h3 {
  max-width: 980px;
  margin: 0 auto 40px;
}
.deal-footer.suscribe-foot h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.deal-footer.suscribe-foot button {
  margin-left: 10px;
}
h2 {
  font-size: 33px;
}
.dfs-title {
  margin-bottom: 50px;
}
.info-item {
  background-color: #13659f;
  background-color: var(--light-blue);
}
.info-items {
  display: flex;
  grid-gap: 2%;
  gap: 2%;
  margin-bottom: 40px;
}
.info-item {
  flex-grow: 1;
  padding: 40px 20px;
  text-align: center;
  flex-basis: inherit;
}
.info-item p {
  margin: 0;
  line-height: 1.2em;
  color: #fff;
  font-size: 15px;
}
.info-item h3 {
  color: #fff;
  font-weight: 600;
  font-size: 22px;
}
section {
  padding: 100px 0;
}
.products-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}
.products-wrapper.col ul li a:hover {
  background-color: #13659f;
  background-color: var(--light-blue);
}
.products-wrapper.col ul li a, .products-wrapper.col ul li span {
  background-color: #212120;
  background-color: var(--dark-grey);
  color: #fff;
  padding: 10px 25px;
  min-width: 220px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  transition: background-color .4s;
}
.products-wrapper.col ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.info-items-section.blue-back {
  background-color: #13659f;
  background-color: var(--light-blue);
}
.info-items-section.blue-back h2 {
  color: #fff;
}
.info-items-section.blue-back .info-item {
  background-color: #fff;
}
.info-items-section.blue-back .info-item h3, .info-items-section.blue-back .info-item p {
  color: #13659f;
  color: var(--light-blue);
}
.mosaic-inner-info {
  padding: 12%;
  padding-left: calc(100vw / 2 - 1320px / 2);
  min-height: 45vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mosaic-section.invertido .mosaic-inner-info {
  padding-right: calc(100vw / 2 - 1320px / 2);
  padding-left: 12%;
}
.mosaic-img {
  background-size: cover;
  background-position: center;
}
.mosaic-inner-info h3 {
  font-weight: 700;
  font-size: 33px;
  margin-bottom: 20px;
}
.mosaic-inner-info p, .mosaic-inner-info li {
  font-size: 18px;
  line-height: 1.2em;
}
.mosaic-inner-info li {
  list-style: none;
  position: relative;
  margin-bottom: 9px;
}
.mosaic-inner-info li::before {
  content: "";
  font-weight: 600;
  background-color: #13659f;
  background-color: var(--light-blue);
  position: absolute;
  left: -14px;
  width: 7px;
  height: 7px;
  top: 9px;
}
.mosaic-inner-info ul {
  padding-left: 15px;
}
.mosaic-section.invertido .row {
  flex-direction: row-reverse;
}
.deal-footer a {
  color: #fff;
}
.deal-footer ul {
  list-style: none;
  padding: 0;
}
.deal-footer h5 {
  font-weight: 600;
  font-size: 18px;
}
.partners-logos {
  padding: 100px 5% 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.partners-logos img {
  max-width: 233px;
  max-height: 150px;
}
.primary-slider h3 {
  font-weight: 700;
  font-size: 50px;
  color: #13659f;
  color: var(--light-blue);
  line-height: 1.1em;
  margin-bottom: 30px;
}
.primary-slider h4 {
  font-weight: 700;
  font-size: 35px;
  color: #13659f;
  color: var(--light-blue);
  line-height: 1.1em;
  margin-bottom: 20px;
}
.primary-slider p {
  color: #212120;
  color: var(--dark-grey);
  font-size: 25px;
  line-height: 1.2em;
}
.primary-slider .dark-layout h3, .primary-slider .dark-layout p, .primary-slider .dark-layout h4 {
  color: #fff;
}
.primary-slider .sr-only {
  display: none;
}
.carousel-control-prev-icon, .carousel-control-next-icon {
  background-color: #000;
  border-radius: 100%;
  padding: 0;
  width: 40px;
  height: 40px;
  background-size: 65%;
  background-position: 45%;
}
.carousel-control-next-icon {
  background-position: 55%;
}
.top-spacer {
  margin-top: 100px;
}
.deal-footer img {
  width: 100%;
  max-width: 180px;
}
.deal-footer.suscribe-foot {
  background-color: #13659f;
  background-color: var(--light-blue);
}
.deal-footer .title-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}
.iframe-cuenta {
  margin: 60px 0;
}
.regular-page-header h1 {
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 20px;
}
.regular-page-header h4 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
  color: #212120;
  color: var(--dark-grey);
}
.regular-page-header {
  padding: 0px 0 40px;
}
.regular-page-header h4 a {
  color: #13659f;
  color: var(--light-blue);
}
.inner-page-section {
  padding: 80px 0;
}
.regular-page-header p {
  font-size: 18px;
}
.inner-section-title, .regular-page-header h2 {
  font-size: 27px;
  font-weight: 700;
  color: #13659f;
  color: var(--light-blue);
  border-bottom: 1px solid #ced4da;
  padding-bottom: 15px;
}
.regular-page-header h2 {
  margin-bottom: 20px;
  padding-bottom: 7px;
}
.biocard-nosotros h2 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
.biocard-nosotros h5 {
  font-size: 16px;
}
.biocard-nosotros {
  flex-basis: 32%;
  border: 1px solid #ced4da;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.biocard-body {
  padding: 20px;
}
.cards-products-style.container p {
  line-height: 1.25em;
}
.biocard-wrapper {
  display: flex;
  grid-gap: 40px 2%;
  grid-gap: 40px 2%;
  gap: 40px 2%;
  flex-wrap: wrap;
  justify-content: center;
}
.nosotros-img {
  height: 240px;
  background-size: cover;
  background-position: center;
  background-color: #f2f2f2;
}
.biocard-body li {
  line-height: 1.2em;
  margin-bottom: 5px;
  list-style: none;
  font-size: 14px;
}
.biocard-body p {
  font-size: 14px;
  line-height: 1.2em;
  margin-bottom: 20px;
}
.cards-products-style.container .MuiButton-textPrimary {
  background-color: #13659f;
  background-color: var(--light-blue);
  color: #fff;
  font-family: 'Inter', Sans Serif;
  font-weight: 600;
  font-size: 13px;
  margin-top: 20px;
}
.cards-products-style.container .blue .MuiButton-textPrimary {
  background-color: #fff;
  color: #13659f;
  color: var(--light-blue);
}
.workmap-img {
  width: 100%;
  height: auto;
}
.familia-fondos-graph {
  position: relative;
}
.link-to-renta-var {
  position: absolute;
  width: 35.5%;
  height: 100%;
  top: 0;
  right: 0;
}
.link-to-renta-fija {
  position: absolute;
  width: 40.5%;
  height: 100%;
  top: 0;
  right: 35.5%;
}
.link-to-liquidez {
  position: absolute;
  width: 24%;
  height: 100%;
  top: 0;
  left: 0;
}
.contact-form {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 12px 4%;
  gap: 12px 4%;
}
.contact-form input, .contact-form textarea {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  flex-basis: 100%;
}
.contact-form input:focus, .contact-form textarea:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}
.contact-form input.half-input {
  flex-basis: 48%;
}
.contact-form button {
  margin: 0 auto;
}
.contact-form input.empty-field {
  box-shadow: 0 0 0 .25rem rgba(253, 13, 13, 0.25);
}
.form-notice {
  flex-basis: 100%;
  text-align: center;
  font-size: 20px;
  color: #13659f;
}
.download-btn:hover {
  background-color: #39b54a;
  color: #fff;
}
.download-btn {
  background-color: #13659f;
  padding: 5px 9px;
  color: #fff;
  transition: all .4s;
}
.download-btn svg {
  width: 19px;
}
.download-holder {
  display: flex;
  flex-direction: column;
  grid-gap: 11px;
  gap: 11px;
  align-items: flex-start;
}
.apertura-holder {
  position: relative;
}
.iframe-cuenta {
  margin: 60px 0;
  z-index: 33;
  position: relative;
}
.lds-ellipsis {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: calc(50% - 40px);
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #0c64a1;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@media (max-width:1460px) {
  .partners-logos img {
    max-width: 205px;
    max-height: 128px;
  }
}
@media (max-width:1380px) {
  .steps-section, section {
    padding: 80px 0;
  }
  .info-item {
    padding: 30px 15px;
  }
  .info-item p {
    font-size: 14px;
  }
  .info-item h3 {
    font-size: 20px;
  }
  .dfs-title {
    font-size: 29px;
    margin-bottom: 40px;
  }
  .mosaic-inner-info p, .mosaic-inner-info li {
    font-size: 16px;
  }
  .mosaic-inner-info h3 {
    font-size: 32px;
    margin-bottom: 15px;
  }
  .primary-slider h4 {
    font-size: 31px;
    margin-bottom: 10px;
  }
  .primary-slider h3 {
    font-size: 42px;
    margin-bottom: 20px;
  }
  .primary-slider p {
    font-size: 20px;
  }
}
@media (max-width:1280px) {
  .partners-logos {
    flex-wrap: wrap;
    justify-content: center;
  }
  .logo-holder {
    flex-basis: 33.33%;
    display: flex;
    justify-content: center;
  }
  .header button {
    font-size: 13px;
  }
}
@media (max-width: 1400px) {
  .mosaic-inner-info {
    padding-left: calc(100vw / 2 - 1140px / 2);
  }
  .mosaic-section.invertido .mosaic-inner-info {
    padding-right: calc(100vw / 2 - 1140px / 2);
    padding-left: 12%;
  }
}
@media (max-width: 1200px) {
  .mosaic-inner-info {
    padding-left: calc(100vw / 2 - 960px / 2);
  }
  .mosaic-section.invertido .mosaic-inner-info {
    padding-right: calc(100vw / 2 - 960px / 2);
    padding-left: 12%;
  }
}
@media (max-width:980px) {
  .biocard-nosotros {
    flex-basis: 49%;
  }
  .top-spacer {
    margin-top: 70px;
  }
  .carousel-caption {
    width: 35%;
  }
  .primary-slider h3 {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .primary-slider h4 {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .primary-slider p {
    font-size: 17px;
    margin: 0;
  }
  .steps-section h3 {
    font-size: 20px;
  }
  .info-items {
    flex-wrap: wrap;
    grid-gap: 13px 2%;
    gap: 13px 2%;
  }
  .info-item {
    flex-basis: 49%;
    flex-grow: unset;
  }
  .dfs-title {
    font-size: 26px;
    margin-bottom: 30px;
  }
  .steps-section, section {
    padding: 60px 0;
  }
  .mosaic-img {
    min-height: 40vh;
  }
  .mosaic-section.regular .row {
    flex-direction: column-reverse;
  }
  .mosaic-inner-info, .mosaic-section.invertido .mosaic-inner-info {
    padding: 60px 5%;
    min-height: auto;
  }
  .mosaic-section.invertido .row {
    flex-direction: column-reverse;
  }
  .partners-logos {
    padding: 40px 5% 0;
    grid-gap: 20px 0;
    gap: 20px 0;
  }
}
@media (max-width:768px) {
  .partners-logos img {
    max-width: 179px;
    max-height: 99px;
  }
  .logo-holder {
    flex-basis: 50%;
  }
  .dfs-title, .MuiTypography-body1.dfs-title {
    font-size: 23px;
    line-height: 1.2em;
  }
  .mosaic-inner-info h3 {
    font-size: 26px;
    margin-bottom: 15px;
  }
  .mosaic-inner-info p, .mosaic-inner-info li {
    font-size: 15px;
  }
  .info-item {
    flex-basis: 100%;
    flex-grow: unset;
  }
  .deal-footer .title-2 {
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
  }
  .biocard-nosotros {
    flex-basis: 100%;
  }
  .regular-page-header h1 {
    font-size: 30px;
  }
  .regular-page-header p {
    font-size: 15px;
  }
  .regular-page-header h2 {
    margin-bottom: 15px;
    padding-bottom: 7px;
    font-size: 22px;
  }
  .inner-page-section {
    padding: 40px 0;
  }
  .regular-page-header h4 {
    font-size: 15px;
  }
  .regular-page-header {
    padding: 0px 0 10px;
  }
  .contact-form input.half-input {
    flex-basis: 100%;
  }
}
